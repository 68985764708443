import JSZip from "jszip";
import Day from "./Day";
import Guid from "./Guid";
import Leg from "./Leg";
import Waypoint from "./Waypoint";
import WaypointType from "./WaypointType";

export async function unzipKmz(file: File): Promise<string | undefined> {
    try {
        const jszip = new JSZip();
        const content = await jszip.loadAsync(file);

        const kmlFile = content.file("doc.kml");
        if (kmlFile) {
            return await kmlFile.async("text");
        } else {
            return undefined;
        }
    } catch (error) {
        console.error("Error unzipping file:", error);
        return undefined;
    }
}

export function parseKml(plainText: string): Day {
    let parser = new DOMParser()
    let xmlDoc = parser.parseFromString(plainText, "text/xml")

    if (xmlDoc.documentElement.nodeName !== "kml") { 
        throw "unsupported file";
    }

    return {
        id: Guid.newGuid(),
        title: getDayTitle(xmlDoc),
        legs: parseLegs(xmlDoc),
        editMode: false
    };
}

function getDayTitle(xmlDoc: Document) {
    const documentNode = xmlDoc.documentElement.getElementsByTagName('Document')[0];
    const title = documentNode.getElementsByTagName('name')[0].childNodes[0].nodeValue;
    return title ?? 'DAY';
}

function parseLegs(xmlDoc: Document): Leg[] {
    const legs: Leg[] = [];

    for (const leg of xmlDoc.getElementsByTagName('Folder') as any) {
        const lineStringsCount = leg.getElementsByTagName('LineString').length;
        if (lineStringsCount == 0) continue;
        
        let legTitle = leg.getElementsByTagName('name')[0].childNodes[0].nodeValue.trim();
        legs.push({
            id: Guid.newGuid(),
            title: legTitle,
            route: parseRoute(leg),
            editMode: false,
            isKml: true,
        })
    }
    
    return legs;
}

function parseRoute(leg: any): Waypoint[] {
    const route: Waypoint[] = [];
    for (const item of leg.getElementsByTagName('Placemark') as any) {
        let lineStrings = item.getElementsByTagName('LineString');
        for (const line of lineStrings) {
            let coordinatesString = line.getElementsByTagName('coordinates')[0].childNodes[0].nodeValue.trim()
            let points = coordinatesString.split("\n")

            for (const point of points) {
                let coordinate = point.split(",")
                route.push({
                    id: Guid.newGuid(),
                    type: WaypointType.Waypoint,
                    coordinates: {lat: +coordinate[1], lng: +coordinate[0]},
                })
            }
        }
    }

    return route;
}