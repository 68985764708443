import React, { useState, useEffect } from 'react';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import update from 'immutability-helper';
import { useLocation, useNavigate } from 'react-router-dom';
import Map from './Map';
import Marker from './Marker';
import DirectionsRenderer from './DirectionsRenderer';
import Sidebar from './Sidebar';
import Waypoint from './Waypoint';
import Guid from './Guid';
import WaypointType from './WaypointType';
import { mapStringToWaypointType, indexToAlpha } from './Utils';
import type { DropResult } from "@hello-pangea/dnd";
import AutocompleteBox from './AutocompleteBox';
import Leg from './Leg';

type EditorProps = {
  updateLeg: (dayId: string, leg: Leg) => Promise<void>,
};

type EditorState = {
  dayId: string,
  leg: Leg
}

const render = (status: Status) => {
  return <h1>{status}</h1>;
};

const Editor = ({ updateLeg }: EditorProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [needDirectionUpdate, setNeedsDirectionUpdate] = useState(false);
  const [waypoints, setWaypoints] = useState<Waypoint[]>([]);
  const [legTitle, setLegTitle] = useState("");
  const [fitBounds, setFitBounds] = useState<google.maps.LatLngBounds | undefined>(undefined);
  const [directions, setDirections] = React.useState<google.maps.DirectionsResult | null>();
  const [zoom, setZoom] = React.useState(6);
  const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({
    lat: 46.485,
    lng: 11.323,
  });

  useEffect(() => {
    if (!location.state) {
      navigate("/");
      return;
    }

    const locationState = location.state as EditorState;
    setLegTitle(locationState.leg.title);

    if (locationState.leg.route && locationState.leg.route.length > 0) {
      const timer = setTimeout(() => {
        setWaypoints(locationState.leg.route);
        setNeedsDirectionUpdate(true);

        var bounds = new google.maps.LatLngBounds();
        locationState.leg.route.forEach(wp => {
          bounds.extend(wp.coordinates);
        });

      setFitBounds(bounds);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [location]);

  useEffect(() => {
    getDirection();
  }, [waypoints]);

  //Use this to clear the sessionStorage of the react router state property, so the user is forwarded to the home page
  //If you do not do this, the user might work for hours on the map tool only to realize after clicking on the save button
  //that nothing was saved.
  useEffect(() => {
    window.history.replaceState({}, document.title)
  }, [])

  const onClick = (e: google.maps.MapMouseEvent) => {
    if (waypoints.length >= 27) {
      alert("Maximum waypoints reached. Only 27 waypoints are allowed.");
    } else {
      let coordinates = {
        lat: e.latLng!.lat(),
        lng: e.latLng!.lng(),
      }
      appendNewWaypoint(coordinates);
    }
  };

  const reorder = (waypoints: Waypoint[], startIndex: number, endIndex: number): Waypoint[] => {
    const result = Array.from(waypoints);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const items = reorder(
      waypoints,
      result.source.index,
      result.destination.index
    )

    setWaypoints(items);
    setNeedsDirectionUpdate(true);
  }

  const placeChanged = (place: google.maps.places.PlaceResult, searchRef: HTMLInputElement) => {
    if (!place.geometry || !place.geometry!.location) {
      if (!place.name) return;
      // Check if input is a valid longitude, latitude
      const regexExp = /^((\-?|\+?)?\d+(\.\d+)?),\s*((\-?|\+?)?\d+(\.\d+)?)$/gi;
      if (regexExp.test(place.name!)) {
        let coordinates = {
          lat: Number(place.name!.split(",")[0]),
          lng: Number(place.name!.split(",")[1])
        }

        setCenter(coordinates);
        if (zoom < 17) {
          setZoom(17);
        }

        if (waypoints.length >= 27) {
          alert("Maximum waypoints reached. Only 27 waypoints are allowed.");
        } else {
          appendNewWaypoint(coordinates);
        }

        searchRef.value = ""
      }

      return;
    }

    let coordinates = {
      lat: place.geometry!.location!.lat(),
      lng: place.geometry!.location!.lng(),
    }

    setCenter(coordinates);
    if (zoom < 17) {
      setZoom(17);
    }

    searchRef.value = ""
  }

  function appendNewWaypoint(coordinates: google.maps.LatLngLiteral) {
    let id = Guid.newGuid();
    let type = waypoints.length ? WaypointType.Waypoint : WaypointType.Start;
    let updatedWaypoints = update(waypoints, { $push: [{ id, type, coordinates }] });
    setWaypoints(updatedWaypoints);
    setNeedsDirectionUpdate(true);
  }

  function deleteWaypoint(id: string) {
    let newWaypoints = waypoints.filter((wp, _) => wp.id !== id);
    setWaypoints(newWaypoints);
    if (newWaypoints.length < 2) {
      setDirections(null);
    } else {
      setNeedsDirectionUpdate(true);
    }
  }

  const save = async () => {
    const locationState = location.state as EditorState;
    const updatedLeg = structuredClone(locationState.leg);
    updatedLeg.route = waypoints;
    await updateLeg(locationState.dayId, updatedLeg);
    navigate("/");
  }

  function updateType(id: string, newType: string) {
    let index = waypoints.findIndex(wp => wp.id === id);
    const updatedWaypoints = update(waypoints, { [index]: { type: { $set: mapStringToWaypointType(newType) } } });
    setWaypoints(updatedWaypoints);
  }

  async function getDirection() {
    if (waypoints.length < 2) return;
    if (!needDirectionUpdate) return;

    if (waypoints.length > 27) {
      return;
    }

    console.log('Calling DirectionsService...');
    const service = new google.maps.DirectionsService();
    const requestData: google.maps.DirectionsRequest = {
      origin: waypoints[0].coordinates,
      destination: waypoints[waypoints.length - 1].coordinates,
      waypoints: waypoints.slice(1, -1).map((wp) => ({ location: new google.maps.LatLng(wp.coordinates), stopover: true })),
      provideRouteAlternatives: false,
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.METRIC,
    };

    try {
      const result: google.maps.DirectionsResult = await service.route(requestData);
      setDirections(result);
      setNeedsDirectionUpdate(false);
      updateWaypointsFromDirection(result);
    } catch (error) {
      console.log(error);
    }
  }

  const onMarkerDragEnd = (e: google.maps.MapMouseEvent, wpId: string) => {
    if (!e.latLng) return;
    let index = waypoints.findIndex(wp => wp.id === wpId);
    const updatedWaypoints = update(waypoints, { [index]: { coordinates: { $set: { lat: e.latLng!.lat(), lng: e.latLng!.lng() } } } });
    setWaypoints(updatedWaypoints);
    setNeedsDirectionUpdate(true);
  }

  function updateWaypointsFromDirection(result: google.maps.DirectionsResult) {
    var updatedWaypoints: Waypoint[] = [];
    var legs = result.routes[0].legs;

    for (let i = 0; i < waypoints.length; i++) {
      const wp = waypoints[i];

      if (i === 0) {
        const leg = legs[i];
        if (waypoints[i].coordinates.lat !== leg.start_location.lat() || waypoints[i].coordinates.lng !== leg.start_location.lng()) {
          updatedWaypoints = update(waypoints, {
            [i]: {
              coordinates: {
                $set: {
                  lat: leg.start_location.lat(),
                  lng: leg.start_location.lng(),
                }
              }
            }
          });
        }
      } else {
        const leg = legs[i - 1];
        if (wp.coordinates.lat !== leg.end_location.lat() || wp.coordinates.lng !== leg.end_location.lng()) {
          updatedWaypoints = update(waypoints, {
            [i]: {
              coordinates: {
                $set: {
                  lat: leg.end_location.lat(),
                  lng: leg.end_location.lng(),
                }
              }
            }
          });
        }
      }

      if (updatedWaypoints.length > 0) {
        setFitBounds(undefined);
        setWaypoints(updatedWaypoints);
      }
    }
  }

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <Wrapper apiKey={'AIzaSyAHPXw6Oe93neIq746BMOQtu8jP84_9nJ8'} render={render} libraries={["places"]}>
        <Map center={center} onClick={onClick} zoom={zoom} style={{ flexGrow: "1", height: "100%" }} fitBounds={fitBounds}>
          <AutocompleteBox placeChanged={placeChanged} />
          {directions ?
            <DirectionsRenderer directions={directions} draggable={directions!.routes.length > 0 ? true : false} preserveViewport={true} suppressMarkers polylineOptions={{ clickable: false, strokeColor: '#0088FF', strokeWeight: 6, strokeOpacity: 0.6 }} />
            : null
          }
          {waypoints.map((wp, i) => (<Marker key={i} position={wp.coordinates} clickable={true} draggable={true} onDragEnd={(e) => onMarkerDragEnd(e, wp.id)} label={{
            text: indexToAlpha(i),
            color: 'white',
            fontSize: "16px",
            fontWeight: "bold"
          }} />))}
        </Map>
      </Wrapper>
      <Sidebar
        waypoints={waypoints}
        legTitle={legTitle}
        onSave={save}
        onDeleteClick={deleteWaypoint}
        onTypeChange={updateType}
        onDragEnd={onDragEnd} />
    </div>
  );
};

export default Editor;
